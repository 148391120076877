<template>
    <v-container fluid>
        <PlanificacionMensualPAC mostrar-graficos />
    </v-container>
</template>
<script>
import PlanificacionMensualPAC from '@/components/planificacionMensualPAC/PlanificacionMensualPAC.vue';

export default {
    components: { PlanificacionMensualPAC },
};
</script>
